class Tags extends HTMLElement {
  constructor(params){
    super();
    this.group_id = Cookies.get('aid_selected_auto_group_id');;
  }

  async connectedCallback(){
    let group_tags_fetch = await fetch(`/admin/groups/${this.group_id}/tags`);
    let group_tags = await group_tags_fetch.json();

    let tag_options = group_tags.data.map((tag) => {return `<option value=${tag.tag_id}>${tag.name}</option>`}).join("");    

    console.log("group_id:"+ this.group_id);
    this.innerHTML = `<select name="tags[]" multiple="multiple" class="form-control selectized" style="display: none;" placeholder="Select tags..." tabindex="-1">${tag_options}</select>`

    $(this.querySelector("select")).selectize({
      maxItems: 10,
      plugins: ['remove_button'],
    });

    // $(this.querySelector("select")).on("change", function() {
    //   const event = new Event('change');
    //   this.dispatchEvent(event);
    //   console.log("change iccured");
    // }.bind(this))
  }

  value() {

    return this.querySelector('select') == null ? [] : Array.from(this.querySelector('select')).map(option => option.value)
  }
}

customElements.define('ai-tags', Tags);