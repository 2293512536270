class RingToEndpoint extends HTMLInputElement {
  constructor() {
    super();

    let is_domain = false;

    // Watch keypresses for @
    $(this).keypress(function(event){
      console.log(event.which)
      let _this = this
      setTimeout(function() {
        if(event.which == 64 && !is_domain) {
          is_domain = true;
          $(_this).unmask();
          $(_this).val($(_this).val().replace(/[^0-9]/g, '')+"@");
          is_domain = true;
        } else if (is_domain && !$(_this).val().includes("@"))  {
          is_domain = false;
          $(_this).mask('(999) 999-9999');
        }
      }, 10);
    })

    // Needed to catch backspace
    $(this).keyup(function(event){
      if(event.keyCode == 8) {
        if(!is_domain && $(this).val().includes("@")) {
          is_domain = true;
          $(this).unmask();
        } else if (is_domain && !$(this).val().includes("@")) {
          is_domain = false;
          $(this).mask('(999) 999-9999')
        }
      }
    });

    // Catch paste events
    $(this).bind("paste", function(event) {
      let pastedData = event.originalEvent.clipboardData.getData('text');
      let _this = this
      this.formatInput(pastedData, this);
    })

    // If not valid wipe out
    $(this).bind("change", function(event) {
      let value = $(this).val();
      if( value != "" && !value.match(/^\(\d{3}\)\s\d{3}-\d{4}/g) && !value.match(/^([0-9]+)@([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])(\.([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]{0,61}[a-zA-Z0-9]))+(:[0-9]+)?$/g )) {
        $(this).val("");
        Common.notify("Please use a valid ring to number or SIP URL.", "warning");
      }
    });
    $(document).ready(function() {
      if(!$(this).val().match(/^(\S+[0-9])@([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])(\.([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]{0,61}[a-zA-Z0-9]))+(:[0-9]+)?$/g )) {
        $(this).mask('(999) 999-9999');
      }
    }.bind(this));
    this.type="text";
    this.maxlength = 10;
  }

  formatInput(input, input_element){
    let parts = input.split('@');
    parts[0] = parts[0].replace(/\D/g,'');
    console.log(parts);
    setTimeout(function(){
      if(parts.length === 1){
          input_element.value = parts[0];
          $(input_element).mask('(999) 999-9999');
      }
      else if(parts.length === 2){
        $(input_element).unmask();
        input_element.value = parts[0]+'@'+parts[1];
      }
    },10);
  }
};

customElements.define('ring-to-endpoint', RingToEndpoint, {
  extends: 'input',
});
