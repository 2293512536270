import {ReportDetailRow} from './report_detail_row'

class UserReports extends HTMLElement {

  constructor() {
    super();
  }

  async connectedCallback() {
    this.is_admin_view = this.getAttribute("admin_view") ? true : false;

    this.innerHTML = `<div class="col-md-12 col-xs-12 col-sm-12">
        ${this.is_admin_view ? `
          <h3>All Scheduled Reports</h3>
          <p>Editing or deleting these reports will affect users.</p>
          ` : `<h3>Scheduled Reports</h3>`}
        <table id="scheduled_reports" class="table" style="width:100%">
          <thead>
            <tr>
              <th></th>
              <th>Title</th>
              <th>Report Type</th>
              <th>Next Run Date</th>
              <th>No. Recipients</th>
              <th>Recipients</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
          </tbody>
        </table>
      </div>`;
    this.init();
  }

  init(user_reports) {
    this.table = $('#scheduled_reports').DataTable({
      ajax: this.is_admin_view ? "/admin/user_reports.json" : "/user_report_schedule.json",
      columns: [
        {
          "class": "details-control",
          "orderable": false,
          "data": null,
          "defaultContent": ""
        },
        {data: "title"},
        {data: "report_type", render: function(data, type, row) {
          return data.split('_').map((word)=> {return word.charAt(0).toUpperCase() + word.slice(1)}).join(' ')
        },
          "class": "text-center"},
        {render: function(data, type, row) {
          return moment(row.next_run_date).format("MM/DD/YYYY");
        },
          "class": "text-center"},
        {render: function(data, type, row) {
          return row.recipients;
        },
          "class": "text-center"},
        {data: "recipient_list", visible: false},
        {render: function(data, type, row) {
          return '<a href="#" class="run_report">Run Now</a>';
        },
          "class": "text-center",
          sortable: false},
      ]
    });

    this.table.on('draw', function () {
      $.each(detailRows, function (i, id) {
        $('#' + id + ' td.details-control').trigger('click');
      });
    });

    let detailRows = [];

    $('#scheduled_reports tbody').on('click', 'tr td.details-control', async function (e) {
      let tr = $(e.target).closest('tr');
      let row = this.table.row(tr);
      let idx = $.inArray(tr.attr('id'), detailRows);

      if (row.child.isShown()) {
        tr.removeClass('details shown');
        row.child.hide();

        detailRows.splice(idx, 1);
      }
      else {
        let oldSelectedTr = $("table#scheduled_reports tr.shown");
            if(oldSelectedTr.size() > 0) {
              let oldSelectedRow = this.table.row(oldSelectedTr);
              oldSelectedTr.next('tr').removeClass('details-row');
              oldSelectedRow.child.hide();
              oldSelectedTr.removeClass('shown');
            }
        tr.addClass('details shown');
        let row_detail = new ReportDetailRow(this.table.row( e.target.closest("tr")).data().user_report_id);
        row.child(row_detail).show();

        if (idx === -1) {
          detailRows.push(tr.attr('id'));
        }
      }
    }.bind(this));

    this.querySelector(".form-inline").classList.remove("form-inline")

    this.initTableEvents();
  }
  
  initTableEvents() {    
    $("#scheduled_reports").on('click', 'a.delete_report', async function (e) {
      if(confirm("Are you sure you want to delete this report?")){
        e.preventDefault();
        var row = this.table.row( e.target.closest("tr").previousSibling ).data();
        let res = await fetch('/user_report_schedule/'+row.user_report_id, 
        {method: "DELETE",
        headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')}
        });
        this.table.row( e.target.closest("tr").previousSibling ).remove().draw();
      }
    }.bind(this));

    $("#scheduled_reports").on('click', 'tr a.run_report', async function (e) {
      e.preventDefault();
      $("#run_report_modal").modal();
      var row = this.table.row( e.target.closest('tr') ).data();
      let user_report_runner = new UserReportRunner(row.user_report_id);
      let modal_dialog_elem = document.querySelector("#run_report_modal .modal-dialog");
      modal_dialog_elem.innerHTML = "";
      modal_dialog_elem.appendChild(user_report_runner);
    }.bind(this));
  }
}

customElements.define('user-reports', UserReports);

class UserReportRunner extends HTMLElement {
  constructor(report_id, save_cb, fail_cb) {
    super();
    this.report_id = report_id;
  }
  async connectedCallback() {
    this.innerHTML = `<div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="exampleModalLabel">Run Scheduled Report</h3>
        </div>
        <div class="modal-body">
          <div class="form-group" style="margin:10px 0px;">
              <label>Run for date range:</label>
              <input type="text" class="form-control" name="dates">
          </div>  
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" id="start_report">Run Report</button>
        </div>
      </div>`;

    let start_date = moment().startOf("day");
    let end_date = moment().endOf("day");
    $('input[name="dates"]').daterangepicker(
      {
        autoApply: true,
        startDate: start_date,
        endDate: end_date,
        maxDate: moment(),
      },
      function (start, end) {
        start_date = start.startOf("day");
        end_date = end.endOf("day");
      }
    );

    $('#start_report').click(async function(e) {
      e.preventDefault();
      let utcStartDate = start_date.utc().format("YYYY-MM-DD HH:mm:ss");
      let utcEndDate = end_date.utc().format("YYYY-MM-DD HH:mm:ss");
      let res = await fetch('/user_report_schedule/'+this.report_id+'/run_report', {
          headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
                    'Content-type': 'application/json'},
          method: 'POST',
          body: `{"start_date":"${utcStartDate} UTC", "end_date":"${utcEndDate} UTC"}`
      });
      let data = await res.json();
      Common.notify('Your report will be emailed when ready.', 'success');
      $("#run_report_modal").modal('hide');
    }.bind(this));
  }
}

customElements.define('user-report-runner', UserReportRunner);