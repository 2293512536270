/*
 * This module consists of a data binder that allows all elements to be updated
 * from data from a single data source, and a base class for web components that
 * to be bound to that data.
 *
 * Usage:
 * To use the bound element create your own class that extends the BoundElement
 * class with "src" set, either in the constructor or as an HTML attribute.
 * The resulting component will automatically update whenever the data binder's
 * data is changed.
 *
 * To use the data binder without the BoundElement class just create a new
 * instance with the desired namespace.
 *
 * let my_binder = new DataBinder("my_namespace");
 * my_binder.data = "Some data"
 *
 * To update an Object automatically register it with the data binder and
 * implement the "requestUpdate" method.
 * myObject = {
 *  data: my_binder.data;
 *  requestUpdate: function(data) {
 *    myObject.data = data;
 *    console.log(myObject.data);
 *  }
 * }
 *
 * For external data like AJAX requests or WebSocket messages the URL of the
 * data source is likely a good namespace to avoid conflicts.
 *
 * let my_remote_binder = new DataBinder("namespace_url.com");
 * my_remote_binder.data = async () => {
 *  let req = await fetch ("namespace_url.com");
 *  let data = await req.json();
 *  return data;
 * }
 *
 */

class DataBinder {
  /**
    namespace - This identifies the data that is being stored. Most likely to be a URL.
    fetchable - If this data is external data.
  */
  constructor(namespace, fetchable, req_opts = null){
    if(!namespace) throw new Error('No data source provided');
    if(DataBinder[namespace]) return DataBinder[namespace];
    else {
      DataBinder[namespace] = this;
      this.src = namespace;
      this.fetchable = fetchable;
      this.req_opts = req_opts;
      this.elements = [];
      if(fetchable)this.fetchData();
      return DataBinder[namespace]
    }
  }
  set data(data){
    this._data = data;
    this.elements.map(e => {e.requestUpdate(data)});
  }
  get data(){
    return this._data;
  }
  register(elmt){
    this.elements.push(elmt);
  }
  unregister(elmt){
    this.elements.splice(this.elements.indexOf(elmt),1);
  }
  async fetchData(){//Only use if the
    if(this.fetchable){
      let res = await fetch(this.src, this.fetch_opts, this.req_opts);
      this.data = await res.json();
    } else throw new Error('fetchData can only be used if DataBinder is instantiated with the nemespace set to a fetchable URL, and fetchable set to true.');
  }
}

export {DataBinder};