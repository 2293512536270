import WaveSurfer from "wavesurfer.js";

export class AudioPlayer extends HTMLElement {
  constructor() {
    super();

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const audioPlayers = document.querySelectorAll("audio-player");
    audioPlayers.forEach((el) => {
      if (el === this) {
        this.wavesurfer.playPause();
      } else {
        // pauses all other audio-player elements on the page
        // so only one element can be played at a time
        el.wavesurfer.pause();
      }
    });
  }

  connectedCallback() {
    this.innerHTML = `
      <div class="container-div">
        <div role="button" class="play-button">
          <i id="play-icon" class="fa fa-play"></i>
        </div>
        <span id="current-time">0:00</span>
        <div class="waveform-container"></div>
        <span id="duration">0:00</span>
      </div>
    `;

    this.playButton = this.querySelector(".play-button");
    this.playIcon = this.querySelector("#play-icon");
    this.currentTime = this.querySelector("#current-time");
    this.waveformContainer = this.querySelector(".waveform-container");
    this.duration = this.querySelector("#duration");

    this.wavesurfer = WaveSurfer.create({
      container: this.waveformContainer,
      barGap: 2,
      barHeight: 2.5,
      barRadius: 1,
      barWidth: 1,
      cursorWidth: 0,
      barMinHeight: 0.5,
      height: 28,
      hideScrollbar: true,
      progressColor: "white",
      responsive: true,
      backend: "MediaElement",
    });

    this.wavesurfer.load(this.getAttribute("src"));

    this.wavesurfer.on("ready", () => {
      this.duration.textContent = calculateTime(this.wavesurfer.getDuration());
    });

    this.playButton.addEventListener("click", this.handleClick);

    this.wavesurfer.on("play", () => {
      this.playIcon.className = "fa fa-pause";
    });

    this.wavesurfer.on("pause", () => {
      this.playIcon.className = "fa fa-play";
    });

    this.wavesurfer.on("audioprocess", () => {
      this.currentTime.textContent = calculateTime(this.wavesurfer.getCurrentTime());
    });
  }

  disconnectedCallback() {
    this.playIcon.className = "fa fa-play";
    this.currentTime.textContent = "0:00";
    this.playButton.removeEventListener("click", this.handleClick);
    this.wavesurfer.destroy();
  }
}

function calculateTime(secs) {
  const minutes = Math.floor(secs / 60);
  const seconds = Math.floor(secs % 60);
  const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  return `${minutes}:${returnedSeconds}`;
}

customElements.define("audio-player", AudioPlayer);
