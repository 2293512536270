export class ReportDetailRow extends HTMLElement {
  constructor(user_report_id) {
    super();
    this.user_report_id = user_report_id;
  }
  async connectedCallback() {
    let rowData = this.data;
    let res = await fetch(`/user_report_schedule/${this.user_report_id}`);
    let data = await res.json();
    this.innerHTML = `
      <div>
        <div class="row" style="margin-bottom: 20px;">
          <div class="col-sm-6">
            <h3>Report Details</h3>
          </div>
          <div class="col-sm-6 text-right">
            <a class="delete_campaign delete_report" href="#"><span class="fa fa-trash" style="font-size:24px;"></span></a>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <div id="group_names" class="badge-list">
              <span>Selected Groups</span>
            </div>
            <div id="location_names" class="badge-list">
              <span>Selected Locations</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 report-divs">
            <div class="form-group">
              <label for="report_name">Name</label>
              <input name="report_name" id="report_name" class="form-control" value="${data.title}"></input>
            </div>
            <div class="form-group">
              <label for="timezone">Timezone</label>
              <select name="timezone" class="form-control" id="report_detail_timezone">
                <option value="US/Pacific" ${data.user_report_schedules[0].timezone == "US/Pacific" ? "selected" : null}>US/Pacific</option>
                <option value="US/Mountain" ${data.user_report_schedules[0].timezone == "US/Mountain" ? "selected" : null}>US/Mountain</option>
                <option value="US/Central" ${data.user_report_schedules[0].timezone == "US/Central" ? "selected" : null}>US/Central</option>
                <option value="US/Eastern" ${data.user_report_schedules[0].timezone == "US/Eastern" ? "selected" : null}>US/Eastern</option>
              </select>
            </div>
            <div class="form-group">
              <div style="display:flex;flex-direction:column;">
                <div class="form-group">
                  <b>Report Timeframe</b><span id="timeframe_error" class="error" style="display:none;">- Timeframe must be selected</span>
                </div>
                <div class="form-group">
                  <label>
                    <input type="radio" name="timeframe" value="DAILY" data-name="Daily" id="daily_recurring" ${data.period_start == "::YESTERDAY::" ? "checked" : null}> Daily
                  </label>
                </div>
                <div class="form-group">
                  <label>
                    <input type="radio" name="timeframe" value="PRIOR_WEEK" data-name="Prior Week" id="weekly_recurring" ${data.period_start == "::LAST_WEEK_START::" ? "checked" : null}> Prior Week (Delivered on Mondays)
                  </label>
                </div>
                <div class="form-group">
                  <label>
                    <input type="radio" name="timeframe" value="MONTH_TO_DATE" data-name="Month To Date" id="mtd_recurring" ${data.period_start == "::MONTH_START::" ? "checked" : null}> Month To Date
                  </label>
                </div>
                <div class="form-group">
                  <label>
                    <input type="radio" name="timeframe" value="PRIOR_MONTH" data-name="Prior Month" id="monthly_recurring" ${data.period_start == "::LAST_MONTH_START::" ? "checked" : null}> Prior Month (Sent on the 1st of each month)
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <label>Recipients</label>
            <div id="report_recipient_group">
            </div>
          </div>
        </div>
        <div class="row">                                                                        
          <button class="btn btn-primary pull-right detail-row-button">Save</button>
          <button class="btn btn-outline-secondary pull-right detail-row-button cancel-report-edit">Cancel</button>
        </div>
      </div>
    `;

    
    // Creates email address inputs with existing addresses if they exist
    if(data.user_report_recipients.length == 0) {
      this.addNewItem();
    }
    else {
      data.user_report_recipients.map(function(recipient_data, i) {
          let reportRecipient = document.createElement("report-detail-recipients");
          reportRecipient.data = {email: recipient_data.email_address, id: recipient_data.user_report_recipient_id};
          reportRecipient.setTarget(recipient_data.destination);
          this.querySelector("#report_recipient_group").appendChild(reportRecipient);
        }.bind(this)
      );
    }

    const groupBadgeList = $("#group_names");
    const groupBadges = data.group_names.map(
      (name) => `<span class="label label-info">${name}</span>`
    );
    if (groupBadges.length === 0) {
      groupBadgeList.remove();
    }
    groupBadges.forEach((badge) => {
      groupBadgeList.append(badge);
    });

    const locationBadgeList = $("#location_names");
    const locationBadges = data.location_names.map(
      (name) => `<span class="label label-info">${name}</span>`
    );
    if (locationBadges.length === 0) {
      locationBadgeList.remove();
    }
    locationBadges.forEach((badge) => {
      locationBadgeList.append(badge);
    });

    this.querySelector(".btn-primary").addEventListener("click", function() {
      this.saveChanges(this.user_report_id)
    }.bind(this));
    this.querySelector(".cancel-report-edit").addEventListener("click", this.closeRow);
  }

  addNewItem() {
    let newReportRecipient = document.createElement('report-detail-recipients');
    this.querySelector('#report_recipient_group').appendChild(newReportRecipient);
  }

  async saveChanges(report_id) {
    let emailElems = document.getElementsByClassName("report_email")
    let emails = [];
    let validRegex = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i;
    let isValid = true;

    this.querySelectorAll('.error').forEach(elem => elem.classList.remove('error'))

    emailElems.forEach(elem => {
      if(elem.value.trim() != "" && !elem.value.match(validRegex)) {
        elem.classList.add('error');
        Common.notify('Invalid email address. Please be sure to enter only one email per line', 'error');
        isValid = false;
      } else {
        emails.push({"email": elem.value, "id": elem.getAttribute("data")})
      }
    });
    if(!isValid) {
      return
    }
    let data = {
      name: this.querySelector("#report_name").value,
      timezone: this.querySelector("#report_detail_timezone").value,
      timeframe: $("input[name='timeframe']:checked").val(),
      emails: emails,
    }
    let saved_changes = await fetch(`/user_report_schedule/${report_id}/update_report`, {
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        'Content-Type': 'application/json'
      },
      method: 'PUT',
      body: JSON.stringify(data)
    });
    let response = await saved_changes.json();
    if (response.ok) {
      Common.notify('Scheduled report updated', 'success');
      $('#scheduled_reports').DataTable().ajax.reload();;
      console.log(this.closest('tr').previousSibling)
    } else {
      Common.notify('Unable to update', 'danger');
    }
  }

  closeRow(e) {
    e.preventDefault();
    this.closest("table").querySelector("tr.shown td.details-control").click();
  }
  
}

customElements.define("report-detail-row", ReportDetailRow)

class ReportDetailRecipients extends HTMLElement {
  constructor() {
    super();
  }
  connectedCallback() {
    this.innerHTML = 
      `<div class="report_recipient_group_item" style="margin-bottom: 3px;">
        <input type="text" is="" class="form-control report_email" data-key="" placeholder="mail@mail.com" style="width: 90%;display: inline-block;" value="${this.data != null ? this.data.email : ""}" data="${this.data != null ? this.data.id : ""}">
        <a href="#" class="remove_item" style="margin-left: 10px;"><i class="fa fa-minus"></i></a>
        <a href="#" class="add_item" style="margin-left: 10px;"><i class="fa fa-plus"></i></a>
      </div>`;
    let _this = this;
    console.log(this.data)
    this.querySelector(".remove_item").addEventListener('click', function(event) {
        event.preventDefault();
        _this.remove();
      });
    this.querySelector(".add_item").addEventListener('click', function(event) {
        event.preventDefault();
        if(document.querySelector("#report_recipient_group").childElementCount < 10) {
          _this.closest("report-detail-row").addNewItem();
        }
      });
  }

  setTarget(target) {
    this.target = target
  }
}

customElements.define("report-detail-recipients", ReportDetailRecipients)